export default {
  props: {
    title: {
      type: Object,
      required: true,
    },

    body: {
      type: String,
    },

    buttons: {
      type: Array,
    },

    appStoreButtons: {
      type: Array,
    },
  },

  components: {
    hotBanner: () => import('~/components/Molecules/Banner/Banner.vue'),
  },
}
